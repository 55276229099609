import benefitsAndFinance from '../img/Categories/Benefits & Finance.svg';
import learningOpportunities from '../img/Categories/Learning Opportunities.svg';
import healthAndSocialCare from '../img/Categories/Health & Social Care.svg';
import legalMatters from '../img/Categories/Legal Matters.svg';
export const legalMattersIcon = legalMatters;
import lifeSkills from '../img/Categories/Life Skills.svg';
import planningForTransition from '../img/Categories/Planning for Transition.svg';
import workOpportunities from '../img/Categories/Work Opportunities.svg';
import transitionTopicsIcon from '../img/transition-topics-grid.svg';
import transitionTopicCategoryIcons from '../img/book.svg';
import transitionQuestionsIcon from '../img/tell-me-more-transitions.svg';
import yesIcon from '../img/YoungPersonFeedbackIcons/yes.svg';
import yesInactiveIcon from '../img/YoungPersonFeedbackIcons/yes-inactive.svg';
import yesActiveIcon from '../img/YoungPersonFeedbackIcons/yes-active.svg';
import notSureIcon from '../img/YoungPersonFeedbackIcons/not-sure.svg';
import notSureInactiveIcon from '../img/YoungPersonFeedbackIcons/not-sure-inactive.svg';
import notSureActiveIcon from '../img/YoungPersonFeedbackIcons/not-sure-active.svg';
import noIcon from '../img/YoungPersonFeedbackIcons/no.svg';
import noInactiveIcon from '../img/YoungPersonFeedbackIcons/no-inactive.svg';
import noActiveIcon from '../img/YoungPersonFeedbackIcons/no-active.svg';
import transitionTopicCategory1 from '../img/YoungPersonCategoryIcons/transition-topic-category-1.svg';
import transitionTopicCategory2 from '../img/YoungPersonCategoryIcons/transition-topic-category-2.svg';
import transitionTopicCategory3 from '../img/YoungPersonCategoryIcons/transition-topic-category-3.svg';
import transitionTopicCategory4 from '../img/YoungPersonCategoryIcons/transition-topic-category-4.svg';
import transitionTopicCategory5 from '../img/YoungPersonCategoryIcons/transition-topic-category-5.svg';
import transitionTopicCategory6 from '../img/YoungPersonCategoryIcons/transition-topic-category-6.svg';
import transitionTopicCategory7 from '../img/YoungPersonCategoryIcons/transition-topic-category-7.svg';
import transitionTopicCategory8 from '../img/YoungPersonCategoryIcons/transition-topic-category-8.svg';

import greenTickIcon from '../img/YoungPersonRatingIcons/Importance/tick-green.svg';
import whiteTickIcon from '../img/YoungPersonRatingIcons/Importance/tick-white.svg';
import greenCircleTickIcon from '../img/YoungPersonRatingIcons/Importance/tick-green-circle.svg';
import greenSquareTickIcon from '../img/YoungPersonRatingIcons/Importance/tick-green-square.svg';
import monoSquareTickIcon from '../img/YoungPersonRatingIcons/Importance/tick-square-mono.svg';
import unsureIcon from '../img/YoungPersonRatingIcons/Importance/unsure.svg';
import unsureWhiteIcon from '../img/YoungPersonRatingIcons/Importance/unsure-white.svg';
import unsureSquareIcon from '../img/YoungPersonRatingIcons/Importance/question-mark-square.svg';
import unsureWhiteSquareIcon from '../img/YoungPersonRatingIcons/Importance/question-mark-square-mono.svg';
import unsureCircleIcon from '../img/YoungPersonRatingIcons/Importance/question-mark-circle.svg';

import redCrossIcon from '../img/YoungPersonRatingIcons/Importance/cross-red.svg';
import whiteCrossIcon from '../img/YoungPersonRatingIcons/Importance/cross-white.svg';
import redCircleCrossIcon from '../img/YoungPersonRatingIcons/Importance/cross-red-circle.svg';
import redSquareCrossIcon from '../img/YoungPersonRatingIcons/Importance/cross-red-square.svg';
import monoSquareCrossIcon from '../img/YoungPersonRatingIcons/Importance/cross-square-mono.svg';

import tickRatingButtonIcon from '../img/YoungPersonRatingIcons/Importance/tick-white-circle.svg';
import unsureRatingButtonIcon from '../img/YoungPersonRatingIcons/Importance/unsure-white-circle.svg';
import crossRatingButtonIcon from '../img/YoungPersonRatingIcons/Importance/cross-white-circle.svg';

import underStandVeryWellInactiveIcon from '../img/YoungPersonRatingIcons/Challenge/very-well-mono.svg';
import underStandVeryWellIcon from '../img/YoungPersonRatingIcons/Challenge/very-well-colour.svg';
import understandABitInactiveIcon from '../img/YoungPersonRatingIcons/Challenge/a-bit-mono.svg';
import understandABitIcon from '../img/YoungPersonRatingIcons/Challenge/a-bit-colour.svg';
import understandNotAtAllInactiveIcon from '../img/YoungPersonRatingIcons/Challenge/not-at-all-mono.svg';
import understandNotAtAllIcon from '../img/YoungPersonRatingIcons/Challenge/not-at-all-colour.svg';

import expandIcon from '../img/expand.svg';
import expandPrimaryIcon from '../img/expand-primary.svg';
import expandSecondaryIcon from '../img/expand-secondary.svg';
import expandWhiteIcon from '../img/expand-white.svg';
import collapseIcon from '../img/collapse.svg';
import collapseSecondaryIcon from '../img/collapse-coral.svg';
import collapseWhiteIcon from '../img/collapse-white.svg';
import collapsePrimaryIcon from '../img/collapse-primary.svg';
import rightFacingArrowIcon from '../img/right-arrow.svg';
import rightFacingArrowSecondaryIcon from '../img/right-arrow-coral.svg';
import leftFacingArrowIcon from '../img/left-arrow.svg';
import leftFacingArrowSecondaryIcon from '../img/left-arrow-coral.svg';

import viewAllCategoriesIcon from '../img/view-all.svg';
import viewMediumCategoriesIcon from '../img/view-medium.svg';
import viewMinimalCategoriesIcon from '../img/view-minimal.svg';

import feedbackIcon from '../img/feedback.png';
import glossaryIcon from '../img/glossary-orange.svg';
import ratingsIcon from '../img/ratings.svg';
import ratingsIconDisabled from '../img/ratings-mono.svg';

import dobIllustration from '../img/Onboarding/yp-dob.svg';
import bigChangeIllustration from '../img/Onboarding/yp-big-change.svg';
import bigChangeDateIllustration from '../img/Onboarding/yp-big-change-date.svg';
import localAuthorityIllustration from '../img/Onboarding/yp-local-authority.svg';
import onboardingThankYouIllustration from '../img/Onboarding/yp-onboarding-thank-you.svg';

export const categoryIcons = {
  'Benefits & Finance': benefitsAndFinance,
  'Learning Opportunities': learningOpportunities,
  'Health & Social Care': healthAndSocialCare,
  'Legal Matters': legalMatters,
  'Life Skills': lifeSkills,
  'Planning for Transition': planningForTransition,
  'Work Opportunities': workOpportunities,
};

export const YOUNG_PERSON_CATEGORY_ICONS = {
  'transition-topic-category-1': transitionTopicCategory1,
  'transition-topic-category-2': transitionTopicCategory2,
  'transition-topic-category-3': transitionTopicCategory3,
  'transition-topic-category-4': transitionTopicCategory4,
  'transition-topic-category-5': transitionTopicCategory5,
  'transition-topic-category-6': transitionTopicCategory6,
  'transition-topic-category-7': transitionTopicCategory7,
  'transition-topic-category-8': transitionTopicCategory8,
};

export const sectionIcons = {
  transitionQuestionsIcon,
  transitionTopicsIcon,
  transitionTopicCategoryIcons,
  feedbackIcon,
  glossaryIcon,
  ratingsIcon,
  ratingsIconDisabled,
};

export const compassArrowIcons = {
  expandIcon: {
    mono: expandIcon,
    secondary: expandSecondaryIcon,
    primary: expandPrimaryIcon,
    white: expandWhiteIcon,
  },
  collapseIcon: {
    mono: collapseIcon,
    secondary: collapseSecondaryIcon,
    primary: collapsePrimaryIcon,
    white: collapseWhiteIcon,
  },
  rightFacingArrowIcon: {
    mono: rightFacingArrowIcon,
    secondary: rightFacingArrowSecondaryIcon,
  },
  leftFacingArrowIcon: {
    mono: leftFacingArrowIcon,
    secondary: leftFacingArrowSecondaryIcon,
  },
};

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const NUM_GLOSSARY_LINKS = 3;
export const NUM_TRANSITION_TOPIC_LINKS = 3;

export const EMAIL_VALIDATION_REGEX_STRING = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';

export const longDateFormatter = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});
export const monthAndYearDateFormatter = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'numeric',
});

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const PASSWORD_REQUIREMENTS = [
  'Be at least 8 characters long',
  'Contain at least 1 number',
  'Contain at least 1 uppercase letter',
  'Contain at least 1 symbol (@$!%*#?&)',
];

export const YP_PASSWORD_REQUIREMENTS = [
  'Be at least 8 characters long',
  'Contain at least 1 number',
  'Contain at least 1 capital letter',
  'Contain at least 1 symbol (@$!%*#?&)',
];

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?]).{8,}$/;

export const EVALUATION_PROMPT_THRESHOLD = process.env.EVALUATION_PROMPT_THRESHOLD;
export const CONTACT_EMAIL_ADDRESS = process.env.CONTACT_EMAIL;
export const BANNER_MESSAGE =
  process.env.BANNER_MESSAGE !== '' ? process.env.BANNER_MESSAGE : undefined;
export const SENTRY_LARAVEL_DSN = process.env.SENTRY_LARAVEL_DSN;
export const SENTRY_TRACES_SAMPLE_RATE = process.env.SENTRY_TRACES_SAMPLE_RATE;
export const APP_ENV = process.env.APP_ENV;

export const PARENT_BASE_URL = `https://${process.env.PARENT_APP_HOST}`;
export const YP_BASE_URL = `https://${process.env.YOUNG_PERSON_APP_HOST}`;
export const PRO_BASE_URL = `https://${process.env.PROFESSIONAL_APP_HOST}`;

// Some environments restrict new signups to specific local authorities
export const RESTRICT_SIGNUP_LOCAL_AUTHORITIES = (process.env.TRIAL_AUTHORITY_NAMES ?? '') !== '';
export const TRIAL_LOCAL_AUTHORITIES = RESTRICT_SIGNUP_LOCAL_AUTHORITIES
  ? process.env.TRIAL_AUTHORITY_NAMES.split(',')
  : [];

export const PARENT_FEEDBACK_RATING_SCALE = [
  {
    value: 5,
    label: 'Strongly agree',
  },
  {
    value: 4,
    label: 'Agree',
  },
  {
    value: 3,
    label: 'Neither agree nor disagree',
  },
  {
    value: 2,
    label: 'Disagree',
  },
  {
    value: 1,
    label: 'Strongly disagree',
  },
];

export const YOUNG_PERSON_FEEDBACK_RATING_SCALE = {
  Yes: {
    value: 3,
    icon: yesIcon,
    inactiveIcon: yesInactiveIcon,
    activeIcon: yesActiveIcon,
  },
  'Not Sure': {
    value: 2,
    icon: notSureIcon,
    inactiveIcon: notSureInactiveIcon,
    activeIcon: notSureActiveIcon,
  },
  No: {
    value: 1,
    icon: noIcon,
    inactiveIcon: noInactiveIcon,
    activeIcon: noActiveIcon,
  },
};

export const TOPIC_IMPORTANCE_RATINGS = {
  1: {
    ratingButtonIcon: tickRatingButtonIcon,
    icon: greenTickIcon,
    whiteIcon: whiteTickIcon,
    squareIcon: greenSquareTickIcon,
    monoSquareIcon: monoSquareTickIcon,
    circleIcon: greenCircleTickIcon,
    storedIn: 'topics that matter to you',
    description: 'A lot',
    summary: 'This topic matters to you right now',
  },
  2: {
    ratingButtonIcon: unsureRatingButtonIcon,
    icon: unsureIcon,
    whiteIcon: unsureWhiteIcon,
    squareIcon: unsureSquareIcon,
    monoSquareIcon: unsureWhiteSquareIcon,
    circleIcon: unsureCircleIcon,
    storedIn: `the topics list, under "Topics you were not sure about"`,
    description: 'I am not sure',
    summary: `You were not sure about this topic`,
  },
  3: {
    ratingButtonIcon: crossRatingButtonIcon,
    icon: redCrossIcon,
    whiteIcon: whiteCrossIcon,
    squareIcon: redSquareCrossIcon,
    monoSquareIcon: monoSquareCrossIcon,
    circleIcon: redCircleCrossIcon,
    storedIn: 'topics to think about later',
    description: 'Not much',
    summary: 'This is a topic to think about later',
  },
};

export const TOPIC_CHALLENGE_RATINGS = {
  1: {
    activeButton: underStandVeryWellIcon,
    inactiveButton: underStandVeryWellInactiveIcon,
    icon: underStandVeryWellIcon,
    summary: 'understand very well',
    ratingButtonCopy: 'Very well',
  },
  2: {
    activeButton: understandABitIcon,
    inactiveButton: understandABitInactiveIcon,
    icon: understandABitIcon,
    summary: 'understand a bit',
    ratingButtonCopy: 'A bit',
  },
  3: {
    activeButton: understandNotAtAllIcon,
    inactiveButton: understandNotAtAllInactiveIcon,
    icon: understandNotAtAllIcon,
    summary: 'do not understand at all',
    ratingButtonCopy: 'Not at all',
  },
};

export const CATEGORY_VIEW_MODES = [
  {
    value: 'all',
    icon: viewAllCategoriesIcon,
    label: 'All',
    description: 'View all in a list (no descriptions)',
  },
  {
    value: 'medium',
    icon: viewMediumCategoriesIcon,
    label: 'Medium',
    description: 'View 2 at a time (with descriptions)',
  },
  {
    value: 'minimal',
    icon: viewMinimalCategoriesIcon,
    label: 'Minimal',
    description: 'View 1 at a time (with descriptions)',
  },
];

export const YOUNG_PERSON_HELPFUL_MESSAGES = [
  'Remember to keep thinking about what is important to you.',
  "It's OK to change your mind about what you want to do next.",
  "It's OK to ask for help or advice from people you trust.",
  'If you are feeling overwhelmed try and focus on one small task at a time.',
];

export const YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL = {
  1: 'Not challenging at all',
  2: 'A little challenging',
  3: 'Very challenging',
};

const currentDate = new Date();
export const CURRENT_YEAR = currentDate.getFullYear();
export const CURRENT_MONTH = currentDate.getMonth();
export const MAX_YP_BIRTH_YEAR = CURRENT_YEAR - 13;
export const MIN_YP_BIRTH_YEAR = MAX_YP_BIRTH_YEAR - 12;

export const MIN_YEAR_OF_BIRTH_PARENT_TOOL = 1990;
export const MAX_YEAR_OF_BIRTH_PARENT_TOOL = CURRENT_YEAR;
export const MIN_TRANSITION_YEAR_YP_TOOL = CURRENT_YEAR;
export const MAX_TRANSITION_YEAR_YP_TOOL = MIN_TRANSITION_YEAR_YP_TOOL + 6;

export const FEEDBACK_STRINGS = {
  parent: {
    feedbackTitle: 'Your transitions experience',
    feedbackButtonTitle: 'Tell us about your transitions experience',
    feedbackHistoryTitle: 'What you have said in the past',
  },
  youngPerson: {
    feedbackTitle: 'How are things going?',
    feedbackButtonTitle: 'Share your experience',
    feedbackHistoryTitle: 'What you have said before',
  },
};

export const YP_ONBOARDING_ILLUSTRATIONS = {
  dob: dobIllustration,
  bigChange: bigChangeIllustration,
  bigChangeDate: bigChangeDateIllustration,
  localAuthority: localAuthorityIllustration,
  thankYou: onboardingThankYouIllustration,
};

export const YP_HELPLINE_NUMBERS = {
  nhsEmergency: '999',
  nhsNonEmergency: '111',
  police: '101',
  childline: '0800 1111',
  theMix: '0808 808 4994',
  shout: '85258',
};

export const PARENT_HELPLINE_NUMBERS = {
  samaritans: '116 123',
  breathingSpace: '0800 83 85 87',
};

export const YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_PDF =
  '/api/transition/topic-rating-summary-csv?format=pdf';
export const YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_CSV =
  '/api/transition/topic-rating-summary-csv?format=csv';
export const YP_ACCOUNT_SUMMARY_DOWNLOAD_LINK_PDF = '/api/export/account-summary';

const JOYRIDE_LOCALE = {
  back: 'Back',
  close: 'Close',
  last: 'Done',
  next: 'Next',
  skip: 'Skip',
  open: 'Open the dialog',
};
export const JOYRIDE_DEFAULT_PROPS = {
  scrollOffset: 64, // AppBar height
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  locale: JOYRIDE_LOCALE,
  styles: {options: {width: 500, zIndex: 1200}}, // sticky AppBar z-index
};

export const TRANSITION_TOPICS_SCREEN_DESCRIPTION_IMPORTANT_CATEGORY_KEYS = [
  'transition-category-7',
  'transition-category-6',
];

export const MATOMO_CLOUD_URL = process.env.MATOMO_CLOUD_URL;
export const MATOMO_SITE_ID = process.env.MATOMO_SITE_ID;

export const DIALOG_URL_PARAMS = {
  KEY: 'dialog',
  GLOSSARY: 'glossary',
  GLOSSARY_TERM: 'term',
  TENT: 'tent',
  BACKPACK: 'backpack',
  UNSURE: 'unsure',
  SUMMARY: 'summary',
};

export const PRO_REGION_TYPES = [
  'Local Authority',
  'Health Board',
  'National (Scotland)',
  'Outside Scotland',
];
