import React, {createContext, useContext, useMemo} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {useMediaQuery} from '@mui/material';

import useLocalStorage from './Util/useLocalStorage';

const DarkModeThemeContext = createContext();

const DarkModeThemeProvider = ({children, getCustomTheme}) => {
  const systemPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [storedIsDarkMode, setIsDarkMode] = useLocalStorage('isDarkMode');
  const isDarkMode = storedIsDarkMode ?? systemPrefersDarkMode;
  const theme = useMemo(() => getCustomTheme(isDarkMode ? 'dark' : 'light'), [isDarkMode]);
  return (
    <DarkModeThemeContext.Provider value={{isDarkMode, setIsDarkMode}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </DarkModeThemeContext.Provider>
  );
};

export const useDarkModeTheme = () => useContext(DarkModeThemeContext);

export default DarkModeThemeProvider;
