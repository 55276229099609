import React from 'react';
import {AppBar, IconButton, Slide, Toolbar, useScrollTrigger} from '@mui/material';
import {Box, Container} from '@mui/system';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import PauseIcon from '@mui/icons-material/Pause';

import {useDarkModeTheme} from '../DarkModeThemeProvider';

const NavBar = ({showConfetti, setShowConfetti}) => {
  const {isDarkMode, setIsDarkMode} = useDarkModeTheme();
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger || showConfetti}>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {showConfetti && (
              <IconButton onClick={() => setShowConfetti(false)} aria-label="Stop animations">
                <PauseIcon />
              </IconButton>
            )}
            <Box sx={{flex: 1}} />
            <IconButton
              onClick={() => setIsDarkMode((current) => !current)}
              sx={{mr: 1}}
              aria-label={`Turn dark mode ${isDarkMode ? 'off' : 'on'}`}>
              <Brightness4Icon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
};

export default NavBar;
