import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';
import {white, getSharedTheme, fontFamily, getSharedComponentStyles} from '../sharedTheme';

// from colors.css
const primaryPale = '#ccdcde';
const primaryLight = '#76b1b9';
const primaryBase = '#457f87';
const primaryDark = '#12545c';
const secondaryPale = '#fed3d0';
const secondaryLight = '#ff9282';
const secondaryBase = '#fe5f55';
const secondaryDark = '#c5282b';
const buttonContainedHover = '#fe6f66';
const buttonContainedFocus = '#fe857d';
const buttonContainedActive = '#fe8f88';
const buttonDisabled = '#fe9b95';
const buttonDisabledText = '#9a6768';
const feedbackColor = '#f8d977';

const getCustomTheme = (mode) => {
  const sharedTheme = getSharedTheme(mode);
  let theme = createTheme({
    typography: {
      fontFamily,
    },
    palette: {
      mode,
      primary: {
        pale: primaryPale,
        light: primaryLight,
        main: primaryBase,
        dark: primaryDark,
        contrastText: white,
      },
      secondary: {
        pale: secondaryPale,
        light: secondaryLight,
        main: secondaryBase,
        dark: secondaryDark,
        contrastText: white,
      },
      tellMeMoreColor: '#f7d5d1',
      transitionColor: '#c0d5e6',
      feedbackColor,
    },
    components: getSharedComponentStyles(
      primaryPale,
      primaryDark,
      secondaryBase,
      buttonContainedHover,
      buttonContainedFocus,
      buttonContainedActive,
      buttonDisabled,
      buttonDisabledText
    ),
  });

  theme = deepmerge(theme, sharedTheme);
  theme = responsiveFontSizes(theme);
  return theme;
};

export default getCustomTheme;
