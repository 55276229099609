import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {SENTRY_LARAVEL_DSN, SENTRY_TRACES_SAMPLE_RATE, APP_ENV} from './Util/constants';

Sentry.init({
  dsn: SENTRY_LARAVEL_DSN,
  environment: APP_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
});
