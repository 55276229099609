import React from 'react';
import {createRoot} from 'react-dom/client';

import '../InitSentry';
import {MatomoAnalyticsProvider} from '../MatomoAnalytics';
import DarkModeThemeProvider from '../DarkModeThemeProvider';
import getCustomTheme from '../Parent/CustomTheme';
import App from './App';

const container = document.getElementById('launch-app');
const root = createRoot(container);

root.render(
  <MatomoAnalyticsProvider>
    <DarkModeThemeProvider getCustomTheme={getCustomTheme}>
      <App />
    </DarkModeThemeProvider>
  </MatomoAnalyticsProvider>
);
