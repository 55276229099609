import React, {useEffect, useState} from 'react';
import LaunchPage from './LaunchPage';
import NavBar from './NavBar';
import {MATOMO_COMPASS_APPLICATIONS, MATOMO_CUSTOM_DIMENSION_IDS} from '../MatomoAnalytics';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

const App = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const {trackPageView, pushInstruction} = useMatomo();
  useEffect(() => {
    pushInstruction(
      'setCustomDimension',
      MATOMO_CUSTOM_DIMENSION_IDS.COMPASS_APPLICATION,
      MATOMO_COMPASS_APPLICATIONS.LAUNCH
    );
    // There's no routing in the launch app, so we simply call trackPageView once when the App mounts
    trackPageView();
  }, []);
  return (
    <>
      <NavBar showConfetti={showConfetti} setShowConfetti={setShowConfetti} />
      <LaunchPage showConfetti={showConfetti} setShowConfetti={setShowConfetti} />
    </>
  );
};

export default App;
