// from variables.css (shared)
export const white = '#ffffff';
export const cream = '#f9f7f3';
export const monoDark = '#1d1e1c';
export const monoPale = '#dee0e3';
export const lightGrey = '#61717e';
export const darkGrey = '#0f1f2a';
export const pink = '#cfaeac';
export const yellow = '#d0b564';
export const glossaryColor = '#f3985e';

export const fontFamily = "'Nunito', sans-serif";
export const fontSizeSmall = '16px';
export const fontSizeMedium = '18px';
export const fontWeightRegular = '400';
export const fontWeightMedium = '500';
export const fontWeightSemibold = '600';
export const fontWeightBold = '700';

export const letterSpacingButton = '1px';
export const buttonShadow1dp =
  '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)';
export const buttonShadow2dp =
  '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)';
export const buttonShadow8dp =
  '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)';

export const getSharedTheme = (mode) => ({
  palette: {
    mode,
    mono: {
      main: darkGrey,
      pale: monoPale,
      light: lightGrey,
      dark: monoDark,
    },
    text: {
      primary: darkGrey,
    },
    background: {
      default: cream,
    },
    cream,
    creamGradient:
      'linear-gradient(0deg,rgb(249, 247, 243, 1) 0%,rgba(249, 247, 243, 0.6) 80%,rgba(249, 247, 243, 0) 100%)',
    darkGrey,
    glossary: {
      main: glossaryColor,
      dark: '#b24d00',
    },
    glossaryColor,
    ...(mode === 'light'
      ? {}
      : {
          // Undo any customisations in sharedTheme that break dark mode
          // These values come from the MUI dark theme defaults
          // https://mui.com/material-ui/customization/dark-mode/
          // This can all be removed if sharedTheme becomes aware of the mode
          text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
          },
          action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            selected: 'rgba(255, 255, 255, 0.16)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
          },
          background: {
            default: '#121212',
            paper: '#121212',
          },
          divider: 'rgba(255, 255, 255, 0.12)',
        }),
  },
  typography: {
    body1: {
      fontFamily,
      fontSize: fontSizeMedium,
      fontWeight: fontWeightRegular,
    },
    body2: {
      fontFamily,
      fontSize: fontSizeSmall,
      fontWeight: fontWeightRegular,
    },
    h1: {
      fontFamily,
      fontSize: '48px',
      fontWeight: fontWeightSemibold,
      letterSpacing: 0,
      margin: 0,
    },
    h2: {
      fontFamily,
      fontSize: '36px',
      fontWeight: fontWeightRegular,
      margin: 0,
    },
    h3: {
      fontFamily,
      fontSize: '24px',
      fontWeight: fontWeightMedium,
      margin: 0,
    },
    h4: {
      fontFamily,
      fontSize: '20px',
      fontWeight: fontWeightSemibold,
      margin: 0,
    },
    h5: {
      fontFamily,
      fontSize: '20px',
      fontWeight: fontWeightRegular,
      margin: 0,
    },
    button: {
      lineHeight: 1.375,
      fontFamily: fontFamily,
      fontSize: fontSizeSmall,
      fontWeight: fontWeightMedium,
      letterSpacing: letterSpacingButton,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          textAlign: 'center',
        },
        paperFullScreen: {
          borderRadius: 0,
          textAlign: 'unset',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: fontSizeMedium,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginTop: 24,
          marginBottom: 36,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: 0,
        },
      },
    },
  },
  breakpoints: {
    // https://getbootstrap.com/docs/5.0/layout/breakpoints/
    // We tend to wrap content in <Container maxWidth='md'>
    // An `md` breakpoint of 768 leads to roughly 80 characters per line,
    // which is good for a11y
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

// These component overrides are used by Parent/YP/Pro apps, but using different colours
export const getSharedComponentStyles = (
  primaryPale,
  primaryDark,
  secondaryBase,
  buttonContainedHover,
  buttonContainedFocus,
  buttonContainedActive,
  buttonDisabled,
  buttonDisabledText
) => ({
  MuiStepConnector: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          '&.MuiStepConnector-line': {
            borderColor: primaryDark,
          },
        },
        '&.Mui-active': {
          '&.MuiStepConnector-line': {
            borderColor: primaryDark,
          },
        },
      },
      line: {
        borderColor: primaryPale,
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: primaryPale,
        '&.Mui-active': {
          width: '1.5em',
          height: '1.5em',
          color: secondaryBase,
          text: {
            fill: darkGrey,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        '&.Mui-completed': {
          color: primaryDark,
          backgroundColor: primaryDark,
          borderRadius: '50%',
        },
      },
      text: {
        fill: 'transparent',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({theme}) =>
        theme.unstable_sx({
          py: 1,
          px: 2,
          my: 1,
          textTransform: 'none',
          borderRadius: 4,
          color: theme.palette.text.primary,
          '&.MuiButton-contained': {
            boxShadow: buttonShadow2dp,
            border: 'none',
            '&:hover': {
              backgroundColor: buttonContainedHover,
              boxShadow: buttonShadow8dp,
            },
            '&:focus': {
              backgroundColor: buttonContainedFocus,
              boxShadow: buttonShadow8dp,
            },
            '&:active': {
              backgroundColor: buttonContainedActive,
              boxShadow: buttonShadow8dp,
            },
            '&.Mui-disabled': {
              backgroundColor: buttonDisabled,
              boxShadow: buttonShadow2dp,
            },
          },
          '&.MuiButton-outlined': {
            boxShadow: buttonShadow2dp,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: secondaryBase,
            '&.Mui-disabled': {
              borderColor: buttonDisabled,
            },
          },
          '&.Mui-disabled': {
            color: buttonDisabledText,
          },
        }),
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: ({theme}) => ({
        backgroundColor: theme.palette.mode === 'light' ? monoPale : monoDark,
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        borderRadius: 12,
      },
      inputRoot: ({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        margin: '20px 0px',
      }),
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
});
