import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import SkateboardingTwoToneIcon from '@mui/icons-material/SkateboardingTwoTone';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import Confetti from 'react-confetti';
import {useWindowSize} from 'react-use';
import {Animate} from 'react-simple-animate';

import ypLogo from '../img/logo/logo_young_person.svg';
import {PARENT_BASE_URL, PRO_BASE_URL, YP_BASE_URL} from '../Util/constants';
import arcLogo from '../img/arc-logo-color.png';

const DARK_TEXT_COLOR = 'rgba(0,0,0,0.87)';

const FAQS = [
  {
    title: 'Who is Compass for?',
    description: [
      'Compass is for young people preparing for change, and for parents and carers of young people aged 14-26 who have additional support needs of any kind, including learning disabilities, physical or mental health difficulties, autism, complex or profound disabilities, and experience of care (either as a young carer or a care experienced young person).',
      'There is also a version of Compass for professionals and practitioners who are supporting young people and their families through the transition to young adult life.',
    ],
  },
  {
    title: 'Will Compass take the place of transition planning?',
    description: [
      'Definitely not!',
      'Transition planning meetings should still happen and anyone involved in supporting young people and families should still be providing information and support.',
      'But Compass helps everyone understand what is involved, explore options and work out their priorities. That way everyone can feel more confident about their part in the process and have a better chance of achieving the right outcomes for each individual family.',
    ],
  },
  {
    title: 'How do I know if Compass is relevant to me?',
    description: [
      'Compass has been developed in close partnership with young people, parents and carers, and professionals to make sure it is relevant and useful. It has been tested with young people, parents and carers to make it the best it can be.',
      'The information is specific to Scotland and we work hard to ensure it is as up to date as possible. However ARC Scotland cannot be responsible for the content of external websites and information sources.',
      'Although Compass doesn’t point you to services that are specific to your area, you can get this information from social services, carers’ organisations and other support groups where you live.',
    ],
  },
  {
    title: 'Is Compass useful where young people have profound and multiple learning disabilities?',
    description: [
      'Compass can be helpful no matter what a young person’s needs might be. The information it provides is specific to each user and based on their answers to the questions it asks, so each user may have a different pathway and different things to explore.',
      'Compass has been developed in partnership with young people, parents and carers, and professionals to make sure the information is relevant and useful.',
    ],
  },
  {
    title: 'Will I need help to use Compass?',
    description: [
      'We’re working hard to make Compass as accessible as possible, and we’ve created resources to help people get started. But some people may still need extra help to use it most effectively.',
    ],
  },
  {
    title: 'How are the three versions of Compass different?',
    description: [
      'The young people’s version helps young people work out what matters to them, and share their views with others. It gives them important information aimed specifically at them, and is likely to be most useful during the time leading up to transition.',
      'The version for parents and carers is intended to be used before, during and after the move into adult services, to support the transition process and keep things on track. It provides tailored information at the relevant time and reminders of key actions through transition and beyond.',
      'CompassPro is designed to help professionals and practitioners provide the best support possible to each young person and their family. It enables a better understanding of statutory duties, and links to information that can be tailored to each family’s needs.',
    ],
  },
  {
    title: 'Can Compass be used where someone is home educated?',
    description: [
      'Yes, all three versions of Compass are useful whether a young person is in school or not.',
      'Compass provides information and guidance to explore options, identify key actions and plan next steps, whatever a young person’s situation.',
    ],
  },
  {
    title: 'Can I have information for two or more young people on my Compass account?',
    description: [
      'Parents and carers can add more young people once they have set up their account.',
      'We suggest setting up the account based on the young person who is closest to leaving school. You can then add further young people as necessary.',
      'You are asked to give each young person a name, which can be a nickname or anything else you want to use to identify them. This will appear at the top of your screen in Compass to remind you which young person’s guidance you are looking at.',
      'Compass for young people and CompassPro do not have this feature.',
    ],
  },
];

const YP_VIDEOS = [
  {
    title: 'How to use Compass',
    description: 'About Compass – MAKAton',
    url: 'https://youtu.be/4gBQX7B4Rk0',
  },
  {
    title: 'Instructions for young people',
    description: 'Click image to download PDF',
    url: 'https://pn2p.scot/wp-content/uploads/How-to-sign-up-for-compass-Instructions.pdf',
  },
  {title: 'How to sign in to Compass', url: 'https://youtu.be/QXocMqKvb0I'},
  {
    title: 'How to select topics that matter to you',
    url: 'https://www.youtube.com/watch?v=Hs7haK7pSlk',
  },
  {
    title: 'How to find information on topics that matter to you',
    url: 'https://www.youtube.com/watch?v=SIFWkrKB1E4',
  },
  {title: 'How to leave feedback', url: 'https://www.youtube.com/watch?v=XRhN_-bbdjM'},
  {
    title: 'How to change things in your account',
    url: 'https://www.youtube.com/watch?v=qxO8ts27LYk',
  },
];

const PARENT_VIDEOS = [
  {
    title: 'Getting started',
    description: 'Setting up an account on the version for parents and carers',
    url: 'https://youtu.be/v8r_ojIZQ7E',
  },
  {
    title: 'Instructions for parents and carers',
    description: 'Click image to download PDF',
    url: 'https://pn2p.scot/wp-content/uploads/Compass-Walkthrough.pdf',
  },
];

const UserCard = ({
  icons = [null, null],
  title,
  signUpLink,
  features = [],
  buttonText = 'Sign Up',
  children,
}) => (
  <Card
    sx={{
      width: '100%',
      borderRadius: 4,
      textAlign: 'center',
      py: 2,
      px: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
    <Stack direction="row" justifyContent="space-evenly">
      {icons[0]}
      <Typography variant="h3" component="h2" gutterBottom>
        {title}
      </Typography>
      {icons[1]}
    </Stack>
    <Divider sx={{mb: 2}} />
    {features.map((feature) => (
      <Typography paragraph key={feature}>
        {feature}
      </Typography>
    ))}
    {children}
    <Box sx={{flex: 1}} />
    <Button component="a" href={signUpLink} variant="contained" color="secondary">
      {buttonText}
    </Button>
  </Card>
);

const ResourcesGrid = ({resources}) => (
  <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} sx={{mb: 8}}>
    {resources.map(({title, description, url}) => (
      <Card key={url} sx={{width: {xs: '100%', sm: 250}, height: {xs: 'unset', sm: 300}}}>
        <Box sx={{minHeight: 140, backgroundColor: '#f5f5f5'}}>
          {url.includes('https://www.youtube.com/watch?v=') ? (
            <LiteYouTubeEmbed id={url.split('https://www.youtube.com/watch?v=')[1]} title={title} />
          ) : (
            <Box
              component="a"
              href={url}
              rel="noreferrer"
              target="_blank"
              sx={{
                height: 140,
                color: DARK_TEXT_COLOR,
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              aria-label={`Click to download ${title}`}>
              <ArticleTwoToneIcon sx={{fontSize: '60pt'}} />
            </Box>
          )}
        </Box>
        <CardContent>
          <Typography variant="h5" component="h4" gutterBottom fontWeight="bold">
            {title}
          </Typography>
          {description && <Typography variant="body1">{description}</Typography>}
        </CardContent>
      </Card>
    ))}
  </Box>
);

const logoPath = `M22.6078 31.0326
C22.7301 31.074 22.8648 31.0496 22.9651 30.9679
C23.0653 30.8863 23.1171 30.7586 23.1023 30.6296
L20.2887 14.7126
L17.8284 0.822225
C17.7443 0.413456 17.4238 0.0960108 17.0165 0.0181434
C16.6091 -0.0597239 16.1953 0.117351 15.9684 0.46667
L8.5519 12.437
L0.0524005 26.1496
C-0.0149525 26.2621 -0.0175463 26.4022 0.0456027 26.5172
C0.108753 26.6321 0.22804 26.7043 0.358477 26.7067
L12.1306 23.8741
C12.3457 23.8174 12.5747 23.8609 12.7545 23.9926
L22.6078 31.0326Z`;

const LaunchPage = ({showConfetti, setShowConfetti}) => {
  const {width, height} = useWindowSize();
  return (
    <>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          drawShape={(ctx) => {
            ctx.stroke(new Path2D(logoPath));
          }}
        />
      )}
      <Box sx={{bgcolor: '#c0d5e6', py: 8, color: DARK_TEXT_COLOR}}>
        <Container maxWidth="xl">
          <Stack alignItems="center" spacing={6} sx={{textAlign: 'center'}}>
            <Box
              component="img"
              sx={{width: {xs: 238, sm: 300, md: 395}, height: {xs: 60, sm: 76, md: 100}}}
              alt="Compass - Guiding you through transitions"
              src={ypLogo}
            />
            <Typography variant="h1">Available Now!</Typography>
            <Animate
              play={showConfetti}
              duration={2}
              delay={0.1}
              easeType="ease-in"
              start={{
                transform: 'translate(0px, 0px)',
              }}
              end={{transform: 'translate(500px, -500px)'}}>
              <RocketLaunchTwoToneIcon
                sx={{fontSize: '80pt'}}
                onClick={() => setShowConfetti(true)}
              />
            </Animate>
          </Stack>
        </Container>
      </Box>
      <Box sx={{py: 8}}>
        <Container maxWidth="xl" sx={{alignSelf: 'center'}}>
          <Stack spacing={6} alignItems={'center'}>
            <Typography
              variant="h3"
              component="div"
              textAlign={'center'}
              maxWidth={'md'}
              sx={{mb: 2}}>
              Compass is an online tool to help young people in Scotland, their parents and carers,
              and the professionals who support them, with the transition to young adult life.
              Compass is FREE and will always be FREE. It was developed by trusted experts at ARC
              Scotland who are here help you.
            </Typography>
            <Stack
              direction={{xs: 'column', md: 'row'}}
              spacing={{xs: 4, md: 2}}
              sx={{width: '100%'}}>
              <UserCard
                icons={[
                  <SchoolTwoToneIcon key="school" />,
                  <SkateboardingTwoToneIcon key="skateboard" />,
                ]}
                signUpLink={YP_BASE_URL}
                title="Young People"
                who="Aged 14-26?"
                features={[
                  'Explore important topics depending on your plans',
                  "Reflect on what's important to you",
                  'Prepare for important conversations with people who are helping you take your next big step ',
                ]}
                buttonText="Sign up as a Young Person"
              />
              <UserCard
                icons={[<ChecklistIcon key="checklist" />, <FamilyRestroomIcon key="family" />]}
                signUpLink={PARENT_BASE_URL}
                title="Parents and Carers"
                who="A parent or carer of someone with additional support needs?"
                features={[
                  "Get a personalised 'to-do' list to keep you on track",
                  'Tell your local authority about your experiences',
                  "Explore our 'Transitions A-Z' to bust the jargon of Transitions",
                ]}
                buttonText="Sign up as a Parent or Carer"
              />
              <UserCard
                icons={[<BusinessCenterTwoToneIcon key="business" />, <InfoIcon key="info" />]}
                signUpLink={PRO_BASE_URL}
                title="Professionals"
                who="Supporting a young person or their family in a professional capacity?"
                features={[
                  'Access a range of topics covering key areas of transitions support',
                  'Access helpful resources to stay up-to-date on best-practices and legislation',
                  'Share your thoughts and suggestions on how Principles into Practice could improve',
                ]}
                buttonText="Sign up as a Professional"
              />
            </Stack>
            <Stack alignItems="center" spacing={4}>
              <Typography variant="h3" component="div" textAlign={'center'} maxWidth={'md'}>
                Developed by{' '}
                <Link href="https://arcscotland.org.uk" target="_blank" rel="noreferrer">
                  ARC Scotland
                </Link>{' '}
                to support{' '}
                <Link href="https://pn2p.scot" target="_blank" rel="noreferrer">
                  Principles into Practice
                </Link>
              </Typography>
              <img src={arcLogo} width={100} height={66} alt="ARC Scotland logo" />
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box sx={{bgcolor: '#ffe9e7', py: 6, color: DARK_TEXT_COLOR}} id="competition">
        <Container maxWidth="lg">
          <Stack textAlign={'center'} sx={{alignItems: 'center'}} spacing={4}>
            <CampaignTwoToneIcon sx={{fontSize: '60pt'}} color="primary" />
            <Typography variant="h2" gutterBottom>
              Help us spread the news!
            </Typography>
            <Box>
              <Typography paragraph>
                Don't forget to let friends, family and support services know about Compass - it's
                free to use anywhere in Scotland to support young people preparing for change.
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box sx={{py: 6}}>
        <Container maxWidth="lg" sx={{alignSelf: 'center'}}>
          <Stack justifyContent="space-between" alignItems="center" sx={{mb: 6}}>
            <PsychologyAltTwoToneIcon sx={{fontSize: '60pt'}} />
            <Typography variant="h2">Frequently Asked Questions</Typography>
          </Stack>
          {FAQS.map(({title, description}) => (
            <Accordion key={title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" component="h3" fontWeight="bold">
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {description.map((paragraph) => (
                  <Typography paragraph key={paragraph}>
                    {paragraph}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>
      <Box sx={{py: 6, bgcolor: '#c0d5e6', color: DARK_TEXT_COLOR}}>
        <Container maxWidth="lg" sx={{alignSelf: 'center'}}>
          <Stack justifyContent="space-between" alignItems="center" sx={{mb: 6}}>
            <OndemandVideoTwoToneIcon sx={{fontSize: '60pt'}} />
            <Typography variant="h2">Need some help getting started?</Typography>
          </Stack>
          <Typography variant="h3" sx={{mb: 4, textDecoration: 'underline'}}>
            For Young People
          </Typography>
          <ResourcesGrid resources={YP_VIDEOS} />
          <Typography variant="h3" sx={{mb: 4, textDecoration: 'underline'}}>
            For Parents and Carers
          </Typography>
          <ResourcesGrid resources={PARENT_VIDEOS} />
        </Container>
      </Box>
    </>
  );
};

export default LaunchPage;
