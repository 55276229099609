import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {MatomoProvider, useMatomo, createInstance} from '@jonkoops/matomo-tracker-react';
import {MATOMO_CLOUD_URL, MATOMO_SITE_ID} from './Util/constants';

const MATOMO_ENABLED = !!MATOMO_CLOUD_URL && !!MATOMO_SITE_ID;

// TODO: consider making these .env vars, because they are configured separately for each environment within Matomo
export const MATOMO_CUSTOM_DIMENSION_IDS = {
  USER_PROFILE_TYPE: 1,
  COMPASS_APPLICATION: 3,
  LOCAL_AUTHORITY: 4,
};

export const MATOMO_COMPASS_APPLICATIONS = {
  YOUNG_PERSON: 'young-person-app',
  PARENT: 'parent-app',
  PROFESSIONAL: 'professional-app',
  LAUNCH: 'launch-app',
};

// If Matomo analytics is not configured for this environment, simply return children
// Otherwise configure the matomo instance and wrap children in a MatomoProvider
export const MatomoAnalyticsProvider = ({children}) => {
  if (MATOMO_ENABLED) {
    const instance = createInstance({
      urlBase: MATOMO_CLOUD_URL,
      siteId: MATOMO_SITE_ID,
      disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
      heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
      },
      linkTracking: false, // optional, default value: true
      configurations: {
        // any valid matomo configuration, all below are optional
        setSecureCookie: true,
        setRequestMethod: 'POST',
        requireCookieConsent: true, // matomo will still attempt to track the user, but will not use cookies until the user consents
      },
    });
    return <MatomoProvider value={instance}>{children}</MatomoProvider>;
  }
  console.log('Note: Matomo is not configured in this environment');
  return children;
};

// trackPageView will fail gracefully if useMatomo is called *not* inside a MatomoProvider
// so it's safe to use this hook whether or not analytics are enabled
export const usePageTracking = (compassApplication) => {
  const location = useLocation();
  const {trackPageView, pushInstruction} = useMatomo();
  if (compassApplication) {
    pushInstruction(
      'setCustomDimension',
      MATOMO_CUSTOM_DIMENSION_IDS.COMPASS_APPLICATION,
      compassApplication
    );
  }
  useEffect(() => {
    trackPageView();
  }, [location.pathname]);
};
